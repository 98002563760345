import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { Cliente } from '../../shared/model/Cliente';
import { format } from 'date-fns';
import { ClienteDTO } from '../../shared/dto/clienteDTO';
import { ClienteEndereco } from '../../shared/model/clienteEndereco';
import { AcaoPropostaEnum } from '../../shared/constantes/acaoPropostaEnum';
import ReprovarOuPendenciarPropostaModal from '../../shared/reprovarOuPendenciarPropostaModal';
import ClienteService from '../../services/clienteService';
import ViaCepService from '../../services/viaCepService';
import CustomAlert from '../../shared/customAlert';
import { EstadosCivis } from '../../shared/constantes/estadosCivis';
import { TiposResidencia } from '../../shared/constantes/tiposResidencia';
import { TiposContaBanco } from '../../shared/constantes/tiposContaBanco';
import { NaturezaOcupacoes } from '../../shared/constantes/naturezasOcupacoes';

function ClienteComponent() {
  const location = useLocation();
  const { idCliente, edicao, idProposta, atualizacaoCadastro } = location.state || {};

  const sexos = [
    { value: '', label: '' },
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Feminino', label: 'Feminino' },
  ];

  const temposResidencia = [
    { value: '', label: '' },
    { value: '1', label: '1 ano' },
    { value: '2', label: '2 anos' },
    { value: '3', label: '3 anos' },
    { value: '4', label: '4 anos' },
    { value: '5', label: '5 anos' },
    { value: '6', label: '6 anos' },
    { value: '7', label: '7 anos' },
    { value: '8', label: '8 anos' },
    { value: '9', label: '9 anos' },
    { value: '10', label: '10 anos' },
    { value: '11', label: '11 anos' },
    { value: '12', label: '12 anos' },
    { value: '13', label: '13 anos' },
    { value: '14', label: '14 anos' },
    { value: '15', label: '15 anos' },
    { value: '+15', label: '+15 anos' },
  ];

  const estadosCivil = EstadosCivis;
  const tiposResidencia = TiposResidencia;
  const naturezaOcupacoes = NaturezaOcupacoes;
  const tiposConta = TiposContaBanco;

  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const [cliente, setCliente] = useState<Cliente>(new Cliente());

  const [nome, setNome] = useState('');
  const [dtNascimento, setDtNascimento] = useState('');
  const [rg, setRg] = useState('');
  const [dtRg, setDtRg] = useState('');
  const [orgaoRg, setOrgaoRg] = useState('');
  const [ufRg, setUfRg] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [sexo, setSexo] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [tipoResidencia, setTipoResidencia] = useState('');
  const [tempoResidencia, setTempoResidencia] = useState('');
  const [selectResCob, setSelectResCob] = useState(false);
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [naturezaOcupacao, setNaturezaOcupacao] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dtAdmissao, setDtAdmissao] = useState('');
  const [selectComCob, setSelectComCob] = useState(false);
  const [renda, setRenda] = useState('');
  const [outrasRendas, setOutrasRendas] = useState('');
  const [beneficiario, setBeneficiario] = useState('');
  const [codigoAutorizacao, setCodigoAutorizacao] = useState('');
  const [mesAnoContraCheque, setMesAnoContraCheque] = useState('');
  const [ref1, setRef1] = useState('');
  const [telefoneRef1, setTelefoneRef1] = useState('');
  const [ref2, setRef2] = useState('');
  const [telefoneRef2, setTelefoneRef2] = useState('');
  const [tipoConta, setTipoConta] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [dv, setDv] = useState('');
  const [nomeConjuge, setNomeConjuge] = useState('');
  const [cpfConjuge, setCpfConjuge] = useState('');
  const [rgConjuge, setRgConjuge] = useState('');
  const [dtNascimentoConjuge, setDtNascimentoConjuge] = useState('');
  const [rendaConjuge, setRendaConjuge] = useState('');
  const [telefoneResidencial, setTelefoneResidencial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [emails, setEmails] = useState('');

  // const [acaoProposta, setAcaoProposta] = useState<AcaoPropostaEnum>();

  const [cepResidencia, setCepResidencia] = useState('');
  const [logradouroResidencia, setLogradouroResidencia] = useState('');
  const [numeroResidencia, setNumeroResidencia] = useState('');
  const [complementoResidencia, setComplementoResidencia] = useState('');
  const [bairroResidencia, setBairroResidencia] = useState('');
  const [localidadeResidencia, setLocalidadeResidencia] = useState('');
  const [ufResidencia, setUfResidencia] = useState('');

  const [cepComercial, setCepComercial] = useState('');
  const [logradouroComercial, setLogradouroComercial] = useState('');
  const [numeroComercial, setNumeroComercial] = useState('');
  const [complementoComercial, setComplementoComercial] = useState('');
  const [bairroComercial, setBairroComercial] = useState('');
  const [localidadeComercial, setLocalidadeComercial] = useState('');
  const [ufComercial, setUfComercial] = useState('');

  const [cepCobranca, setCepCobranca] = useState('');
  const [logradouroCobranca, setLogradouroCobranca] = useState('');
  const [numeroCobranca, setNumeroCobranca] = useState('');
  const [complementoCobranca, setComplementoCobranca] = useState('');
  const [bairroCobranca, setBairroCobranca] = useState('');
  const [localidadeCobranca, setLocalidadeCobranca] = useState('');
  const [ufCobranca, setUfCobranca] = useState('');

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<AcaoPropostaEnum | null>(null);
  const [tipoPendenciaRecusa, setTipoPendenciaRecusa] = useState<string>('R');

  const clienteService: ClienteService = new ClienteService();
  const viaCepService: ViaCepService = new ViaCepService();

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalConfirm = (motivoSelect: string) => {
    if (currentAction) {
      handleSubmit(currentAction, motivoSelect);
    }
    setShowModal(false);
  };

  const checkResCob = () => {
    if (cepResidencia !== '' && logradouroResidencia !== '' && numeroResidencia !== '') {
      if (!selectResCob)
        setSelectComCob(false);

      setSelectResCob(!selectResCob);
      setCepCobranca(cepResidencia);
      setLogradouroCobranca(logradouroResidencia);
      setNumeroCobranca(numeroResidencia);
      setComplementoCobranca(complementoResidencia);
      setBairroCobranca(bairroResidencia);
      setLocalidadeCobranca(localidadeResidencia);
      setUfCobranca(ufResidencia);
    }
  };

  const checkComCob = () => {
    if (cepComercial !== '' && logradouroComercial !== '' && numeroComercial !== '') {
      if (!selectComCob)
        setSelectResCob(false);

      setSelectComCob(!selectComCob);
      setCepCobranca(cepComercial);
      setLogradouroCobranca(logradouroComercial);
      setNumeroCobranca(numeroComercial);
      setComplementoCobranca(complementoComercial);
      setBairroCobranca(bairroComercial);
      setLocalidadeCobranca(localidadeComercial);
      setUfCobranca(ufComercial);
    }
  };

  const listarCliente = async () => {
    try {
      setLoading(true);
      await clienteService.listarClientePorId(idCliente)
        .then((responseList) => {
          const json = responseList.data.cliente;
          const jsonEnderecos = responseList.data.enderecos;
          setCliente(json);
          setNome(json.nome);
          setDtNascimento(format(new Date(json.dtNascimento), 'dd/MM/yyyy'));
          setRg(json.numeroRg);
          setDtRg(json.dtEmissaoRg ? format(new Date(json.dtEmissaoRg), 'dd/MM/yyyy') : '');
          setOrgaoRg(json.orgaoRg);
          setUfRg(json.ufRg);
          setNaturalidade(json.ufNaturalidade);
          setSexo(json.sexo);
          setEstadoCivil(json.estadoCivil);
          setTipoResidencia(json.tipoResidencia);
          setTempoResidencia(json.tempoResidencia);
          setNomePai(json.nomePai);
          setNomeMae(json.nomeMae);
          setNaturezaOcupacao(json.naturezaOcupacao);
          setEmpresa(json.empresa);
          setCnpj(json.cnpjEmpresa);
          setDtAdmissao(json.dtAdmissao ? format(new Date(json.dtAdmissao), 'dd/MM/yyyy') : '');
          setRenda(json.renda !== null ? json.renda.toString() : '');
          setOutrasRendas(json.outrasRendas !== null ? json.outrasRendas.toString() : '');
          setBeneficiario(json.numeroBeneficiario);
          setCodigoAutorizacao(json.codigoAutorizacao);
          setMesAnoContraCheque(json.mesAnoContraCheque);
          setRef1(json.refNome1);
          setTelefoneRef1(json.refTelefone1);
          setRef2(json.refNome2);
          setTelefoneRef2(json.refTelefone2);
          setTipoConta(json.tipoConta);
          setBanco(json.banco);
          setAgencia(json.agencia);
          setConta(json.conta);
          setDv(json.contaDv);
          setNomeConjuge(json.nomeConjuge);
          setCpfConjuge(json.cpfConjuge);
          setRgConjuge(json.rgConjuge);
          setDtNascimentoConjuge(json.dtNascimentoConjuge ? format(new Date(json.dtNascimentoConjuge), 'dd/MM/yyyy') : '');
          setRendaConjuge(json.rendaConjuge !== null ? json.rendaConjuge.toString() : '');
          setTelefoneResidencial(json.telefoneResidencial);
          setTelefoneCelular(json.telefoneCelular);
          setTelefoneComercial(json.telefoneComercial);
          setEmails(json.email);

          if (jsonEnderecos !== undefined) {
            jsonEnderecos.forEach((end: ClienteEndereco) => {
              if (end.tipoEndereco === 'Comercial') {
                setCepComercial(end.cep);
                setLogradouroComercial(end.logradouro);
                setNumeroComercial(end.numero);
                setBairroComercial(end.bairro);
                setComplementoComercial(end.complemento);
                setLocalidadeComercial(end.localidade);
                setUfComercial(end.uf);
              } else if (end.tipoEndereco === 'Residencial') {
                setCepResidencia(end.cep);
                setLogradouroResidencia(end.logradouro);
                setNumeroResidencia(end.numero);
                setBairroResidencia(end.bairro);
                setLocalidadeResidencia(end.localidade);
                setUfResidencia(end.uf);
              } else if (end.tipoEndereco === 'Cobrança') {
                setCepCobranca(end.cep);
                setLogradouroCobranca(end.logradouro);
                setNumeroCobranca(end.numero);
                setBairroCobranca(end.bairro);
                setLocalidadeCobranca(end.localidade);
                setUfCobranca(end.uf);
              }
            })
          }
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const formatDate = (date: String) => {
    const partesData = date.split('/');
    const dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
    return new Date(dataFormatada);
  }

  const handleSubmit = async (acaoPropostaEnum: AcaoPropostaEnum, motivoSelect: string | null, event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (sexo === '' || sexo === null || estadoCivil === '' || estadoCivil === null || tipoConta === '' || tipoConta === null || beneficiario === '' || beneficiario === null) return setAlert({ message: 'Preencha todos os campos obrigatórios', type: 'warning' });
    if (!cnpjValidacao.isValid(cnpj) && cnpj !== '' && cnpj !== null && cnpj !== undefined) {
      setAlert({ message: 'CNPJ Inválido', type: 'warning' });
      return;
    } else {
      try {
        setLoading(true);
        let client: Cliente = new Cliente();
        client.id = parseInt(idCliente!);
        client.cpf = cliente.cpf;
        client.nome = nome;
        client.dtNascimento = cliente.dtNascimento!;
        client.numeroRg = rg;
        client.dtEmissaoRg = formatDate(dtRg);
        client.orgaoRg = orgaoRg;
        client.ufRg = ufRg;
        client.ufNaturalidade = naturalidade;
        client.sexo = sexo;
        client.estadoCivil = estadoCivil;
        client.tipoResidencia = tipoResidencia;
        client.tempoResidencia = tempoResidencia;

        client.nomePai = nomePai;
        client.nomeMae = nomeMae;
        client.naturezaOcupacao = naturezaOcupacao;
        client.empresa = empresa;
        client.cnpjEmpresa = cnpj;
        client.dtAdmissao = formatDate(dtAdmissao);

        client.renda = parseFloat(renda);
        client.outrasRendas = parseFloat(outrasRendas);
        client.numeroBeneficiario = beneficiario;
        client.codigoAutorizacao = codigoAutorizacao;
        client.mesAnoContraCheque = mesAnoContraCheque;
        client.refNome1 = ref1;
        client.refTelefone1 = telefoneRef1;
        client.refNome2 = ref2;
        client.refTelefone2 = telefoneRef2;
        client.tipoConta = tipoConta;
        client.banco = banco;
        client.agencia = agencia;
        client.conta = conta;
        client.contaDv = dv;
        client.nomeConjuge = nomeConjuge;
        client.cpfConjuge = cpfConjuge;
        client.rgConjuge = rgConjuge;
        client.dtNascimentoConjuge = formatDate(dtNascimentoConjuge);
        client.rendaConjuge = parseFloat(rendaConjuge);

        client.telefoneCelular = telefoneCelular;
        client.telefoneComercial = telefoneComercial;
        client.telefoneResidencial = telefoneResidencial;
        client.email = emails;

        let enderecoRes: ClienteEndereco = new ClienteEndereco();
        enderecoRes.idCliente = parseInt(idCliente!);
        enderecoRes.tipoEndereco = 'Residencial';
        enderecoRes.cep = cepResidencia;
        enderecoRes.logradouro = logradouroResidencia
        enderecoRes.numero = numeroResidencia;
        enderecoRes.complemento = complementoResidencia;
        enderecoRes.bairro = bairroResidencia;
        enderecoRes.localidade = localidadeResidencia;
        enderecoRes.uf = ufResidencia;
        let enderecoCom: ClienteEndereco = new ClienteEndereco();
        enderecoCom.idCliente = parseInt(idCliente!);
        enderecoCom.tipoEndereco = 'Comercial';
        enderecoCom.cep = cepComercial;
        enderecoCom.logradouro = logradouroComercial
        enderecoCom.numero = numeroComercial;
        enderecoCom.complemento = complementoComercial;
        enderecoCom.bairro = bairroComercial;
        enderecoCom.localidade = localidadeComercial;
        enderecoCom.uf = ufComercial;
        let enderecoCob: ClienteEndereco = new ClienteEndereco();
        enderecoCob.idCliente = parseInt(idCliente!);
        enderecoCob.tipoEndereco = 'Cobrança';
        enderecoCob.cep = cepCobranca;
        enderecoCob.logradouro = logradouroCobranca;
        enderecoCob.numero = numeroCobranca;
        enderecoCob.complemento = complementoCobranca;
        enderecoCob.bairro = bairroCobranca;
        enderecoCob.localidade = localidadeCobranca;
        enderecoCob.uf = ufCobranca;

        let enderecos: Array<ClienteEndereco> = [];
        enderecos.push(enderecoRes);
        enderecos.push(enderecoCom);
        enderecos.push(enderecoCob);

        let clienteDTO: ClienteDTO = new ClienteDTO();
        clienteDTO.cliente = client;
        clienteDTO.enderecos = enderecos;
        clienteDTO.propostaEsteira = (edicao === 'proposta' ? true : false);
        clienteDTO.idMotivo = motivoSelect;
        console.log(atualizacaoCadastro)
        await clienteService.incluirAlterarCliente(idProposta, acaoPropostaEnum, atualizacaoCadastro, clienteDTO);
        setLoading(false);
        
        setAlert({ message: 'Alteração realizada com sucesso!', type: 'success' });

        await sleep(2000);

        if (atualizacaoCadastro === 'true')
          window.location.href = '/cliente-consulta/ATUALIZACAO';
        else
          window.location.href = '/esteira-proposta/FLUXO';
      } catch (err: any) {
        setAlert({ message: err.response.data, type: 'error' });
        setLoading(false);
        console.error(err);
      }

    }
  };

  const viaCep = async (cep: string, tipoEndereco: number) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        if (tipoEndereco === 1) {
          setLogradouroResidencia(jsonViaCep.logradouro);
          setLocalidadeResidencia(jsonViaCep.localidade);
          setUfResidencia(jsonViaCep.uf);
          setBairroResidencia(jsonViaCep.bairro);
        } else if (tipoEndereco === 2) {
          setLogradouroComercial(jsonViaCep.logradouro);
          setLocalidadeComercial(jsonViaCep.localidade);
          setUfComercial(jsonViaCep.uf);
          setBairroComercial(jsonViaCep.bairro);
        } else if (tipoEndereco === 3) {
          setLocalidadeCobranca(jsonViaCep.localidade);
          setUfCobranca(jsonViaCep.uf);
          setBairroCobranca(jsonViaCep.bairro);
          setLogradouroCobranca(jsonViaCep.logradouro);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    listarCliente();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Cliente &gt; Alteração</h5>
        <Button
          style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}
          onClick={() => window.history.back()}
        > Voltar
        </Button>
      </div>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : false}
      <form>
        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Dados Básicos</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2 form-group">
                <label>Código</label>
                <text className="form-control" display={'none'}
                  style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{cliente.id}
                </text>
              </div>
              <div className="col-md-3">
                <label>CPF</label>
                <text className="form-control" display={'none'}
                  style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{cliente.cpf}
                </text>
              </div>

              <div className="col-md-4">
                <label>Nome <span style={{ color: 'red' }}>*</span></label>
                <input type="text" required className="form-control"
                  value={nome} onChange={(e) => setNome(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Data Nascimento</label>
                <text className="form-control" display={'none'}
                  style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}> {dtNascimento}
                </text>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-3 ">
                <label>RG <span style={{ color: 'red' }}>*</span></label>
                <input type="text" required className="form-control"
                  value={rg} onChange={(e) => setRg(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Data Emissão do RG <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="99/99/9999" className="form-control" type="text"
                  value={dtRg} onChange={(e) => setDtRg((e.target.value).replace(/_/g, ""))}
                />
              </div>

              <div className="col-md-3">
                <label>Órgão Emissor <span style={{ color: 'red' }}>*</span></label>
                <input type="text" required className="form-control"
                  value={orgaoRg} onChange={(e) => setOrgaoRg(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>UF Emissão <span style={{ color: 'red' }}>*</span></label>
                <input type="text" maxLength={2} required className="form-control"
                  value={ufRg} onChange={(e) => setUfRg(e.target.value)}
                />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-2">
                <label>Naturalidade UF <span style={{ color: 'red' }}>*</span></label>
                <input type="text" maxLength={2} required className="form-control"
                  value={naturalidade} onChange={(e) => setNaturalidade(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <label>Sexo <span style={{ color: 'red' }}>*</span></label>
                <select required className="form-select" value={sexo}
                  onChange={(e) => setSexo(e.target.value)}>
                  {sexos.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-2">
                <label>Estado Civil <span style={{ color: 'red' }}>*</span></label>
                <select required className="form-select" value={estadoCivil}
                  onChange={(e) => setEstadoCivil(e.target.value)}>
                  {estadosCivil.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label>Tipo de Residência</label>
                <select className="form-select" value={tipoResidencia}
                  onChange={(e) => setTipoResidencia(e.target.value)}>
                  {tiposResidencia.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label>Tempo de Residência</label>
                <select className="form-select" value={tempoResidencia}
                  onChange={(e) => setTempoResidencia(e.target.value)}>
                  {temposResidencia.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              {loading ? <Spinner loading={loading} /> : false}
              <div className="col-md-2">
                <label>CEP <span style={{ color: 'red' }}>*</span></label>
                <InputMask mask="99.999-999" type="text" className="form-control" required
                  value={cepResidencia}
                  onChange={(e) => setCepResidencia(e.target.value)}
                  onBlur={(e) => viaCep(e.target.value, 1)}
                />
              </div>
              <div className="col-md">
                <label>Endereço <span style={{ color: 'red' }}>*</span></label>
                <input type="text" className="form-control" required value={logradouroResidencia} onChange={(e) => setLogradouroResidencia(e.target.value)} />
              </div>
              <div className="col-md-3">
                <label>Número <span style={{ color: 'red' }}>*</span></label>
                <input type="number" className="form-control" required value={numeroResidencia} onChange={(e) => setNumeroResidencia(e.target.value)} />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <label>Bairro <span style={{ color: 'red' }}>*</span></label>
                <input type="text" className="form-control" required value={bairroResidencia} onChange={(e) => setBairroResidencia(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Complemento</label>
                <input type="text" className="form-control" value={complementoResidencia} onChange={(e) => setComplementoResidencia(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Cidade <span style={{ color: 'red' }}>*</span></label>
                <input type="text" className="form-control" required value={localidadeResidencia} onChange={(e) => setLocalidadeResidencia(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Estado <span style={{ color: 'red' }}>*</span></label>
                <InputMask mask="aa" type="text" required className="form-control" value={ufResidencia} onChange={(e) => setUfResidencia(e.target.value.toUpperCase())} />
              </div>
            </div>

            <div className="col-xl">
              <label>
                <input type="checkbox" checked={selectResCob} onChange={checkResCob} />
                Usar o Endereço Residencial para Cobrança
              </label>
            </div>

            <hr className="my-4" />
            <h5>Filiação</h5>

            <div className="row">
              <div className="col-md-5">
                <label className="form-label">Nome Mãe <span style={{ color: 'red' }}>*</span></label>
                <input type="text" required className="form-control"
                  value={nomeMae} onChange={(e) => setNomeMae(e.target.value)}
                />
              </div>
              <div className="col-md-5">
                <label className="form-label">Nome Pai</label>
                <input type="text" className="form-control"
                  value={nomePai} onChange={(e) => setNomePai(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Comercial</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <label>Natureza Ocupação</label>
                <select className="form-select" value={naturezaOcupacao}
                  onChange={(e) => setNaturezaOcupacao(e.target.value)}>
                  {naturezaOcupacoes.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label>Empresa</label>
                <input type="text" className="form-control"
                  value={empresa} onChange={(e) => setEmpresa(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>CNPJ (se sócio proprietário)</label>
                <InputMask mask="99.999.999.9999/99" type="text" className="form-control"
                  value={cnpj} onChange={(e) => setCnpj((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-3">
                <label>Data Admissão</label>
                <InputMask mask="99/99/9999" className="form-control" type="text"
                  value={dtAdmissao} onChange={(e) => setDtAdmissao((e.target.value).replace(/_/g, ""))}
                />
              </div>
            </div>

            <div className="row">
              {loading ? <Spinner loading={loading} /> : false}
              <div className="col-md-2">
                <label>CEP</label>
                <InputMask mask="99.999-999" type="text" className="form-control"
                  value={cepComercial}
                  onChange={(e) => setCepComercial(e.target.value)}
                  onBlur={(e) => (viaCep(e.target.value, 2))}
                />
              </div>
              <div className="col-md">
                <label>Endereço</label>
                <input type="text" className="form-control" value={logradouroComercial} onChange={(e) => setLogradouroComercial(e.target.value)} />
              </div>
              <div className="col-md-3">
                <label>Número</label>
                <input type="number" className="form-control" value={numeroComercial} onChange={(e) => setNumeroComercial(e.target.value)} />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <label>Bairro</label>
                <input type="text" className="form-control" value={bairroComercial} onChange={(e) => setBairroComercial(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Complemento</label>
                <input type="text" className="form-control" value={complementoComercial} onChange={(e) => setComplementoComercial(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Cidade</label>
                <input type="text" className="form-control" value={localidadeComercial} onChange={(e) => setLocalidadeComercial(e.target.value)} />
              </div>
              <div className="col-md">
                <label>Estado</label>
                <InputMask mask="aa" type="text" className="form-control" value={ufComercial} onChange={(e) => setUfComercial(e.target.value.toUpperCase())} />
              </div>
            </div>

            <div className="col-xl">
              <label>
                <input type="checkbox" checked={selectComCob} onChange={checkComCob} />
                Usar o Endereço Comercial para Cobrança
              </label>
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Rendas</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <label>Renda</label>
                <input className="form-control" type="number"
                  value={renda} onChange={(e) => setRenda(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <label>Outras Rendas</label>
                <input className="form-control" type="number"
                  value={outrasRendas} onChange={(e) => setOutrasRendas(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Nº Benefício/Matrícula  <span style={{ color: 'red' }}>*</span></label>
                <input required type="text" className="form-control"
                  value={beneficiario} onChange={(e) => setBeneficiario(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Código Autorização</label>
                <input type="text" className="form-control"
                  value={codigoAutorizacao} onChange={(e) => setCodigoAutorizacao(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <label>Mês/Ano Contra-Cheque</label>
                <InputMask mask="99/9999" className="form-control"
                  value={mesAnoContraCheque} onChange={(e) => setMesAnoContraCheque((e.target.value).replace(/_/g, ""))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Referências Pessoais</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label>Ref Pessoal 1</label>
                <input type="text" className="form-control"
                  value={ref1} onChange={(e) => setRef1(e.target.value)}
                />
              </div>
              <div className="col-md">
                <label>Telefone Celular</label>
                <InputMask mask="(99)99999-9999" type="text" className="form-control"
                  value={telefoneRef1} onChange={(e) => setTelefoneRef1((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-4">
                <label>Ref Pessoal 2</label>
                <input type="text" className="form-control"
                  value={ref2} onChange={(e) => setRef2(e.target.value)}
                />
              </div>
              <div className="col-md">
                <label>Telefone Celular</label>
                <InputMask mask="(99)99999-9999" type="text" className="form-control" maskChar={null}
                  value={telefoneRef2} onChange={(e) => setTelefoneRef2((e.target.value).replace(/_/g, ""))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Dados Bancários</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <label>Tipo Conta <span style={{ color: 'red' }}>*</span></label>
                <select required className="form-select" value={tipoConta}
                  onChange={(e) => setTipoConta(e.target.value)}>
                  {tiposConta.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label>Banco <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="999" type="text" className="form-control"
                  value={banco} onChange={(e) => setBanco((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-2">
                <label>Agência <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="9999" type="text" className="form-control"
                  value={agencia} onChange={(e) => setAgencia((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-2">
                <label>Conta <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="999999999" type="text" className="form-control" maskChar={null}
                  value={conta} onChange={(e) => setConta((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-2">
                <label>DV <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="9" type="text" className="form-control"
                  value={dv} onChange={(e) => setDv((e.target.value).replace(/_/g, ""))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Cônjuge</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <label>Nome Cônjuge</label>
                <input type="text" className="form-control"
                  value={nomeConjuge} onChange={(e) => setNomeConjuge(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>CPF Cônjuge</label>
                <InputMask mask="999.999.999-99" type="text" className="form-control"
                  value={cpfConjuge} onChange={(e) => setCpfConjuge((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md-3">
                <label>RG Cônjuge</label>
                <input type="text" className="form-control"
                  value={rgConjuge} onChange={(e) => setRgConjuge(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Data de Nascimento</label>
                <InputMask mask="99/99/9999" className="form-control" type="text"
                  value={dtNascimentoConjuge} onChange={(e) => setDtNascimentoConjuge(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Renda Cônjuge</label>
                <input type="text" className="form-control"
                  value={rendaConjuge} onChange={(e) => setRendaConjuge(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Endereço Cobrança</strong>
          </div>
          <div className="card-body">
            {!selectResCob && !selectComCob ? (
              <>
                <div className="row">
                  {loading ? <Spinner loading={loading} /> : false}
                  <div className="col-md-2">
                    <label>CEP</label>
                    <InputMask mask="99.999-999" type="text" className="form-control"
                      value={cepCobranca}
                      onChange={(e) => setCepCobranca(e.target.value)}
                      onBlur={(e) => viaCep(e.target.value, 3)} />
                  </div>
                  <div className="col-md">
                    <label>Endereço</label>
                    <input type="text" className="form-control" value={logradouroCobranca} onChange={(e) => setLogradouroCobranca(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <label>Número</label>
                    <input type="number" className="form-control" value={numeroCobranca} onChange={(e) => setNumeroCobranca(e.target.value)} />
                  </div>
                </div><div className="row">
                  <div className="col-md">
                    <label>Bairro</label>
                    <input type="text" className="form-control" value={bairroCobranca} onChange={(e) => setBairroCobranca(e.target.value)} />
                  </div>
                  <div className="col-md">
                    <label>Complemento</label>
                    <input type="text" className="form-control" value={complementoCobranca} onChange={(e) => setComplementoCobranca(e.target.value)} />
                  </div>
                  <div className="col-md">
                    <label>Cidade</label>
                    <input type="text" className="form-control" value={localidadeCobranca} onChange={(e) => setLocalidadeCobranca(e.target.value)} />
                  </div>
                  <div className="col-md">
                    <label>Estado</label>
                    <InputMask mask="aa" type="text" className="form-control" value={ufCobranca} onChange={(e) => setUfCobranca(e.target.value.toUpperCase())} />
                  </div>
                </div>
              </>

            ) : null}

            {selectResCob && !selectComCob ? (
              <>
                <div className="row">
                  {loading ? <Spinner loading={loading} /> : false}
                  <div className="col-md-2">
                    <label>CEP</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{cepResidencia}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Endereço</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{logradouroResidencia}
                    </text>
                  </div>
                  <div className="col-md-3">
                    <label>Número</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{numeroResidencia}
                    </text>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <label>Bairro</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{bairroResidencia}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Complemento</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{complementoResidencia}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Cidade</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{localidadeResidencia}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Estado</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{ufResidencia}
                    </text>
                  </div>
                </div>
              </>
            ) : null}

            {!selectResCob && selectComCob ? (
              <>
                <div className="row">
                  {loading ? <Spinner loading={loading} /> : false}
                  <div className="col-md-2">
                    <label>CEP</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{cepComercial}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Endereço</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{logradouroComercial}
                    </text>
                  </div>
                  <div className="col-md-3">
                    <label>Número</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{numeroComercial}
                    </text>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <label>Bairro</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{bairroComercial}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Complemento</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{complementoComercial}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Cidade</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{localidadeComercial}
                    </text>
                  </div>
                  <div className="col-md">
                    <label>Estado</label>
                    <text className="form-control" display={'none'}
                      style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{ufComercial}
                    </text>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header">
            <strong>Contato</strong>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md">
                <label>Telefone Residencial</label>
                <InputMask mask="(99)9999-9999" type="text" className="form-control"
                  value={telefoneResidencial} onChange={(e) => setTelefoneResidencial((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md">
                <label>Telefone Celular <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="(99)99999-9999" type="text" className="form-control"
                  value={telefoneCelular} onChange={(e) => setTelefoneCelular((e.target.value).replace(/_/g, ""))}
                />
              </div>
              <div className="col-md">
                <label>Telefone Comercial</label>
                <InputMask mask="(99)9999-9999" type="text" className="form-control"
                  value={telefoneComercial} onChange={(e) => setTelefoneComercial((e.target.value).replace(/_/g, ""))}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <label>Lista de E-mails (separados por ';') <span style={{ color: 'red' }}>*</span></label>
                <input type="text" required className="form-control"
                  value={emails} onChange={(e) => setEmails(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {edicao === 'edicao' ? (
          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type='submit'
                onClick={(e) => handleSubmit(AcaoPropostaEnum.APROVAR, null, e)}>Atualizar Cliente
              </button>
            </div>
          </div>
        ) : (
          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type='button'
                onClick={(e) => { handleSubmit(AcaoPropostaEnum.APROVAR, null, e); }}>Atualizar Cliente e Aprovar
              </button>
              <button style={{ backgroundColor: 'YELLOW', color: 'BLACK' }}
                className="btn btn-secondary" type='button'>
                <i className="fa fa-hourglass"
                  onClick={() => {
                    setCurrentAction(AcaoPropostaEnum.PENDENCIAR);
                    setTipoPendenciaRecusa("P");
                    setShowModal(true);
                  }}
                >
                  Pendenciar</i>
              </button>
              <button
                style={{ backgroundColor: 'RED', color: 'White' }}
                className="btn btn-secondary" type='button'>
                <i className="fa fa-hourglass"
                  onClick={() => {
                    setCurrentAction(AcaoPropostaEnum.REPROVAR);
                    setTipoPendenciaRecusa("R");
                    setShowModal(true);
                  }}
                >
                  Reprovar</i>
              </button>
              <ReprovarOuPendenciarPropostaModal
                showModal={showModal}
                handleModalCancel={handleModalCancel}
                handleModalConfirm={(motivoSelect) => handleModalConfirm(motivoSelect)}
                tipoPendenciaRecusa={tipoPendenciaRecusa}
              />
            </div>
          </div>
        )}

      </form>

    </div>


  );
}
export default ClienteComponent;