import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { format } from 'date-fns';
import { EsteiraAtividadesDTO } from '../../shared/dto/EsteiraAtividadesDTO';
import { PropostaObservacao } from '../../shared/model/propostaObservacao';
import { PropostaEsteira } from '../../shared/model/propostaEsteira';
import PropostaEsteiraService from '../../services/propostaEsteiraService';
import PropostaObservacaoService from '../../services/propostaObservacaoService';

function EsteiraAtividades() {
  const location = useLocation();
  const { idProposta } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [esteiraAtividadesDTO, setEsteiraAtividadesDTO] = useState<EsteiraAtividadesDTO>();
  const [propostaEsteira, setPropostaEsteira] = useState<PropostaEsteira[]>([]);
  const [propostaObservacao, setPropostaObservacao] = useState<PropostaObservacao[]>([]);
  const [checkObsBanco, setCheckObsBanco] = useState(false);
  const [obs, setObs] = useState('');
  const [refresh, setRefresh] = useState(false);

  const propostaEsteiraService: PropostaEsteiraService = new PropostaEsteiraService();
  const propostaObservacaoService: PropostaObservacaoService = new PropostaObservacaoService();

  const listarPropostaEsteira = async () => {
    setLoading(true);
    console.log(idProposta)
    try {
      const response = await propostaEsteiraService.listarPropostaAtividadePorIdProposta(idProposta);
      console.log(response.data)
      const jsonResponse = response.data;
      setEsteiraAtividadesDTO(jsonResponse);
      setPropostaEsteira(jsonResponse.propostaEsteiraList);
      setPropostaObservacao(jsonResponse.propostaObservacaoList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleClickObs = async () => {
    setLoading(true);
    let propostaObs = new PropostaObservacao();
    propostaObs.obsBanco = checkObsBanco;
    propostaObs.observacao = obs;
    propostaObs.proposta = Number(idProposta);
    
    try {
      await propostaObservacaoService.inclusaoPropostaObservacao(propostaObs);
      setRefresh(!refresh);
      setObs('');
      setLoading(false);
      setCheckObsBanco(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarPropostaEsteira();
  }, [refresh]);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Esteira &gt; Cartão Consignado &gt; Atividades Executadas</h5>
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}> Voltar</Button>
      </div>

      {loading ? <Spinner loading={loading} /> : (
        <>
          <div className="card">
            <div className="card-header ">
              <strong>Dados Proposta</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-1">
                  <dl className="dl-horizontal">
                    <dt>Nº</dt>
                    <dd>
                      <span>{esteiraAtividadesDTO?.idProposta}</span></dd>
                  </dl>
                </div>
                <div className="col-lg-2">
                  <dl className="dl-horizontal">
                    <dt>CPF</dt>
                    <dd>
                      <span>{esteiraAtividadesDTO?.cpfCliente}</span></dd>
                  </dl>
                </div>
                <div className="col-lg-3">
                  <dl className="dl-horizontal">
                    <dt>Nome</dt>
                    <dd>
                      <span>{esteiraAtividadesDTO?.nomeCliente}</span></dd>
                  </dl>
                </div>
                <div className="col-lg-2">
                  <dl className="dl-horizontal">
                    <dt>Valor RMC</dt>
                    <dd>
                      <span>R$ {esteiraAtividadesDTO?.valorRMC}</span></dd>
                  </dl>
                </div>
                <div className="col-lg-2">
                  <dl className="dl-horizontal">
                    <dt>Valor Limite</dt>
                    <dd>
                      <span>R$ {esteiraAtividadesDTO?.valorLimite}</span></dd>
                  </dl>
                </div>
                <div className="col-lg-2">
                  <dl className="dl-horizontal">
                    <dt>Valor Saque</dt>
                    <dd>
                      <span>R$ {esteiraAtividadesDTO?.limiteSaque}</span></dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Status da Proposta</strong>
            </div>
            <div className="card-body">
              <div style={{ width: '100%', borderWidth: '1px' }}>
                <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                  <tbody>
                    <tr className="GridHeaderStyle" style={{ backgroundColor: '#5a8e91', color: 'White' }}>
                      <th scope="col" id="App-header-table">Status</th>
                      <th scope="col" id="App-header-table">Data</th>
                      <th scope="col" id="App-header-table">Usuário</th>
                      <th scope="col" id="App-header-table">Nr</th>
                      <th scope="col" id="App-header-table">Atividade</th>
                      <th scope="col" id="App-header-table">Ação</th>
                      <th scope="col" id="App-header-table">Motivo</th>
                    </tr>
                    {propostaEsteira.map((item) => (
                      <tr className="GridRowStyle">
                        <td>{item.situacaoProposta}</td>
                        <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</td>
                        <td>{item.usuario}</td>
                        <td>{item.numeroAtividade}</td>
                        <td>{item.atividade}</td>
                        <td>{item.acao ? item.acao : 'NaoDefinido'}</td>
                        <td>&nbsp;</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Observações</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>
                      Observação
                    </span>
                    <text display={'none'} className="form-control form-control-sm brds" style={{ color: 'Black', backgroundColor: 'LightGrey', height: '120px', textAlign: 'left' }}>
                      {propostaObservacao.map((item) => (
                        <>
                          <span>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</span>
                          <span> - {item.usuario} - </span>
                          <span> {item.observacao} </span>
                          <br />
                        </>
                      ))}
                    </text>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>
                      Nova Observação
                    </span>
                  </div>
                  <div>
                    <textarea
                      style={{ height: '80px', textAlign: 'left', width: '100%' }}
                      value={obs}
                      onChange={(e) => setObs(e.target.value)}
                    />
                    <input
                      type="checkbox"
                      checked={checkObsBanco}
                      onChange={() => setCheckObsBanco(!checkObsBanco)}
                    />
                    <label>Observação interna do Banco</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <a className="btn btn-success" onClick={handleClickObs}>
                      <i className="fa fa-save"> Gravar Observação</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
}

export default EsteiraAtividades;
