import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEye } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../../shared/spinner';
import ReactInputMask from 'react-input-mask';
import { OperacaoDTO } from '../../../shared/dto/operacaoDTO';
import { format } from 'date-fns';
import OperacaoService from '../../../services/operacaoService';
import CustomAlert from '../../../shared/customAlert';

function ConsultaContratoComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(false);

  const [selectValue, setSelectValue] = useState('CPF');
  const [inputValue, setInputValue] = useState('');
  const [contratoList, setContratoList] = useState<OperacaoDTO[]>([]);
  const [primeiraMsg, setPrimeiraMsg] = useState(false);

  const operacaoService: OperacaoService = new OperacaoService();

  const handleSelectChange = (e: any) => {
    setSelectValue(e.target.value);
    setInputValue('');
  };

  const listarContrato = async () => {
    if (inputValue === '') return setAlert({ message: 'Preencha o campo de busca', type: 'warning' });
    try {
      setContratoList([]);
      setPrimeiraMsg(true);
      setLoading(true);
      const responseList = await operacaoService.listarContratoPorFiltro(selectValue, inputValue);
      setContratoList(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Consulta de Contrato</h5>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <><div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Consulta de Contrato</th>
                  </tr>
                </thead>
                <tbody>
                  <td>
                    <div style={{ display: 'flex', width: '50%', backgroundColor: 'white' }}>
                      <select className="form-select" style={{ backgroundColor: 'LightGrey', marginRight: '10px', width: '25%' }}
                        value={selectValue}
                        onChange={handleSelectChange}>
                        <option style={{ backgroundColor: 'white' }} value="CPF">CPF</option>
                        <option style={{ backgroundColor: 'white' }} value="CONTRATO">CONTRATO</option>
                        <option style={{ backgroundColor: 'white' }} value="NOME">NOME</option>
                        <option style={{ backgroundColor: 'white' }} value="PROPOSTA">PROPOSTA</option>
                      </select>
                      {selectValue === 'CPF' ? (
                        <ReactInputMask style={{ color: 'Black', textAlign: 'left', width: '60%' }} required mask="999.999.999-99" type="text" className="form-control" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                      ) : (
                        <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="form-control form-control-sm brds"
                          style={{ color: 'Black', textAlign: 'left', width: '60%' }}
                        />
                      )}
                      <button className="input-group-btn"
                        style={{
                          backgroundColor: 'white', width: '5%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                          justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '40px', minWidth: '50px'
                        }} onClick={listarContrato}>
                        <a>
                          <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                        </a>
                      </button>
                    </div>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
            {primeiraMsg ? (
              <div className="card" style={{ marginTop: '25px' }}>
                <div className="card-header" style={{ backgroundColor: 'White', color: 'White', height: '2rem' }}>
                </div>
                {contratoList.length === 0 ? (
                  <span style={{ backgroundColor: 'LightGrey' }}>Nenhum Contrato localizado para este filtro!</span>
                ) : (
                  <div className="card-body">
                    <div style={{ width: '100%', borderWidth: '1px' }}>
                      <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                        <tbody>
                          <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Contrato</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Proposta</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Numero Contrato Externo</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">CPF</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Nome</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Matricula</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Data Base</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Valor Financiado</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Prazo</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Valor Parcela</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Data Liquidacao</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Numero Parcelas Liquidadas</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Situação</th>
                            <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Detalhe</th>
                          </tr>
                          {contratoList.map((item) => (
                            <tr className="GridRowStyle">
                              <td style={{ textAlign: 'center' }}>{item.numeroContrato}</td>
                              <td style={{ textAlign: 'center' }}>{item.proposta}</td>
                              <td style={{ textAlign: 'center' }}>{item.numeroContratoExterno}</td>
                              <td style={{ textAlign: 'center' }}>{item.cpfCliente}</td>
                              <td style={{ textAlign: 'center' }}>{item.nomeCliente}</td>
                              <td style={{ textAlign: 'center' }}>{item.matriculaCliente}</td>
                              <td style={{ textAlign: 'center' }}>{item.dtBase ? format(new Date(item.dtBase), 'dd/MM/yyyy') : ''}</td>
                              <td style={{ textAlign: 'center' }}>R$ {item.valorFinanciado}</td>
                              <td style={{ textAlign: 'center' }}>{item.prazo}</td>
                              <td style={{ textAlign: 'center' }}>R$ {item.valorParcela}</td>
                              <td style={{ textAlign: 'center' }}>{item.dtLiquidacao ? format(new Date(item.dtLiquidacao), 'dd/MM/yyyy') : ''}</td>
                              <td style={{ textAlign: 'center' }}>{item.numeroParcelasLiquidadas ? item.numeroParcelasLiquidadas : 0}</td>
                              <td style={{ textAlign: 'center' }}>{item.ativo ? (item.prazo > item.numeroParcelasLiquidadas ? 'Em aberto' : 'Liquidado') : 'Estornado'}</td>
                              <td style={{ textAlign: 'center' }}>
                                <Link to={`/backoffice-contrato/detalhe`} state={{ contrato: item.numeroContrato }}
                                  style={{ border: '1px solid White', backgroundColor: 'transparent', }}
                                  className="btn btn-outline-primary btn-sm">
                                  <FontAwesomeIcon icon={faEye} style={{ color: 'black' }} />
                                </Link>
                              </td>
                              {/* <td>&nbsp;</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div >
    </>
  );
}

export default ConsultaContratoComponent;