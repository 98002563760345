import React, { ChangeEvent, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import { Produto } from '../../../shared/model/produto';
import { Financiamento } from '../../../shared/model/financiamento';
import { format, isBefore, parse, startOfDay } from 'date-fns';
import { FinanciamentoTaxa } from '../../../shared/model/financiamentoTaxa';
import { FinanciamentoProduto } from '../../../shared/model/financiamentoProduto';
import FinanciamentoService from '../../../services/financiamentoService';
import ProdutoService from '../../../services/produtoService';
import CustomAlert from '../../../shared/customAlert';
import { getLabelTipoProp, TiposProposta } from '../../../shared/constantes/tiposProposta';

function FinanciamentoInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const tipoProposta = TiposProposta;

  const tipoFormalização = [
    { value: 'Física', label: 'Física' },
    { value: 'Digital', label: 'Digital' },
  ];

  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [dtVigenciaInicio, setDtVigenciaInicio] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtVigenciaFim, setDtVigenciaFim] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [prazoInicio, setPrazoInicio] = useState('');
  const [prazoFim, setPrazoFim] = useState('');
  const [taxa, setTaxa] = useState('');
  const [percentComissao, setPercentComissao] = useState('');

  const [tabelaTaxas, setTabelaTaxas] = useState<FinanciamentoTaxa[]>([]);

  const [produtos, setProdutos] = useState<Produto[]>([]);

  const [tabelaProdutosFinanciamento, setTabelaProdutosFinanciamento] = useState<FinanciamentoProduto[]>([]);

  const [tpProposta, setTpProposta] = useState(tipoProposta[0].value);
  const [produto, setProduto] = useState('');
  const [formalizacao, setFormalizacao] = useState(tipoFormalização[0].value);

  const [loading, setLoading] = useState(false);

  const financiamentoService: FinanciamentoService = new FinanciamentoService();
  const produtoService: ProdutoService = new ProdutoService();

  const listarProdutos = async () => {
    try {
      const response = await produtoService.listarTodosProdutos();
      setProdutos(response.data);
      setProduto(response.data[0].id?.toString()!);

    } catch (err) {
      console.error(err);
    }
  }

  const listarFinanciamentoDTOPorId = async () => {
    setLoading(true);
    try {
      const response = await financiamentoService.listarFinanciamentoDTOPorId(id);
      console.log(response.data);
      const jsonFinanciamento = response.data;
      setCodigo(jsonFinanciamento.id.toString());
      setCodigo(jsonFinanciamento.codigo);
      setNome(jsonFinanciamento.nome);
      setDtVigenciaInicio(format(new Date(jsonFinanciamento.dtVigenciaInicio), 'dd/MM/yyyy'))
      setDtVigenciaFim(format(new Date(jsonFinanciamento.dtVigenciaFim), 'dd/MM/yyyy'))
      setTabelaTaxas(jsonFinanciamento.financiamentoTaxa);
      setTabelaProdutosFinanciamento(jsonFinanciamento.financiamentoProduto);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const adicionarDadoTabelaTaxa = () => {
    if (!prazoInicio || !prazoFim || !taxa) {
      setAlert({ message: 'Preencha todos os campos da tabela de prazo e taxas!', type: 'warning' });
      return;
    }
    if (tabelaTaxas.some(item => (item.prazoInicio <= parseInt(prazoInicio) && parseInt(prazoInicio) <= item.prazoFim) ||
      (parseInt(prazoFim) >= item.prazoInicio && parseInt(prazoFim) <= item.prazoFim))) {
      setAlert({ message: 'Prazo já cadastrado!', type: 'warning' });
      return;
    }
    let prazoTaxa: FinanciamentoTaxa = new FinanciamentoTaxa();
    prazoTaxa.prazoInicio = parseInt(prazoInicio!);
    prazoTaxa.prazoFim = parseInt(prazoFim!);
    prazoTaxa.taxa = parseFloat(taxa.replace(',', '.'));
    prazoTaxa.percentComissao = parseFloat(percentComissao.replace(',', '.'));

    setTabelaTaxas([...tabelaTaxas, prazoTaxa]);
    setPrazoInicio('');
    setPrazoFim('');
    setTaxa('');
    setPercentComissao('');
  }
  const adicionarDadoTabelaFinanciamentoProduto = () => {
    console.log(produto)
    if (!tpProposta || !produto || !formalizacao) {
      setAlert({ message: 'Preencha todos os campos de Produtos!', type: 'warning' });
      return;
    }
    if (tabelaProdutosFinanciamento.some(item => item.tipoProposta === tpProposta)) {
      setAlert({ message: 'Tipo proposta já cadastrado!', type: 'warning' });
      return;
    }
    let tabelaProd: FinanciamentoProduto = new FinanciamentoProduto();
    tabelaProd.tipoProposta = tpProposta;
    let produtoo = new Produto();
    produtoo.id = parseInt(produto);
    produtoo.nome = produtos.find(item => item.id === parseInt(produto))?.nome!;
    tabelaProd.produto = produtoo;
    tabelaProd.formalizacao = formalizacao;

    setTabelaProdutosFinanciamento([...tabelaProdutosFinanciamento, tabelaProd]);
  }

  const removerDadoTabelaTaxa = (index: number) => {
    const newTableTax = [...tabelaTaxas];
    newTableTax.splice(index, 1);
    setTabelaTaxas(newTableTax);
  }

  const removerDadoTabelaProduto = (index: number) => {
    const newTableProd = [...tabelaProdutosFinanciamento];
    newTableProd.splice(index, 1);
    setTabelaProdutosFinanciamento(newTableProd);
  }

  const limparCampos = () => {
    setCodigo('');
    setNome('');
    setDtVigenciaInicio(format(new Date(), 'dd/MM/yyyy'));
    setDtVigenciaFim(format(new Date(), 'dd/MM/yyyy'));
    setPrazoInicio('');
    setPrazoFim('');
    setTaxa('');
    setPercentComissao('');
    setTabelaTaxas([]);
    setTpProposta('');
    setProduto('');
    setFormalizacao('');
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (tabelaTaxas == null || tabelaTaxas.length < 1) {
      setAlert({ message: 'Taxas não cadastrado! Favor incluir antes de continuar!', type: 'warning' });
      return;
    } else if (tabelaProdutosFinanciamento == null || tabelaProdutosFinanciamento.length < 1) {
      setAlert({ message: 'Prazos não cadastrado! Favor incluir antes de continuar!', type: 'warning' });
      return;
    } else {
      console.log(dtVigenciaInicio)
      console.log(new Date())
      if (parse(dtVigenciaInicio, "dd/MM/yyyy", new Date()) > parse(dtVigenciaFim, "dd/MM/yyyy", new Date())) return setAlert({ message: 'Data de Início deve ser menor que a Data de Fim', type: 'warning' });
      if (isBefore(startOfDay(parse(dtVigenciaInicio, "dd/MM/yyyy", new Date())), startOfDay(new Date()))) return setAlert({ message: 'Data de Vigência Inicial não deve ser inferior que a Data Atual', type: 'warning' });

      setLoading(true);
      let financiamento: Financiamento = new Financiamento();
      financiamento.id = id == null ? '' : id;
      financiamento.codigo = codigo;
      financiamento.nome = nome;
      const partesData = dtVigenciaInicio.split('/');
      const dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
      const novaData = new Date(dataFormatada);
      financiamento.dtVigenciaInicio = new Date(novaData);
      const partesData2 = dtVigenciaFim.split('/');
      const dataFormatada2 = partesData2[2] + '-' + partesData2[1] + '-' + partesData2[0];
      const novaData2 = new Date(dataFormatada2);
      financiamento.dtVigenciaFim = new Date(novaData2);
      financiamento.financiamentoTaxa = tabelaTaxas;
      financiamento.financiamentoProduto = tabelaProdutosFinanciamento;

      try {
        await financiamentoService.incluirAlterarFinanciamento(financiamento)
          .then(() => setLoading(false));

        setAlert({ message: 'Inclusão realizada com sucesso!', type: 'success' });
        await sleep(2000);
        window.location.href = '/parametros-financiamento';
      } catch (err: any) {
        setLoading(false);
        setAlert({ message: err.response.data, type: 'error' });
        console.error(err);
      }
    }
  };

  const formatarValorMonetario = (valor: any) => {
    if (!valor) return '0,00';
    const valorComCentavos = valor.padStart(3, '0');
    const reais = valorComCentavos.slice(0, -2);
    const centavos = valorComCentavos.slice(-2);
    return `${parseInt(reais, 10)},${centavos}`;
  };
  const handleChangeTaxa = (e: ChangeEvent<HTMLInputElement>) => {
    const valorDigitado = e.target.value.replace(/\D/g, '');
    const valorFormatado = formatarValorMonetario(valorDigitado);
    setTaxa(valorFormatado);
  };

  const handleChangeComissao = (e: ChangeEvent<HTMLInputElement>) => {
    const valorDigitado = e.target.value.replace(/\D/g, '');
    const valorFormatado = formatarValorMonetario(valorDigitado);
    setPercentComissao(valorFormatado);
  };

  useEffect(() => {
    listarProdutos();
    if (tipo === 'alteracao') {
      listarFinanciamentoDTOPorId();
    }
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Parâmetros Cartão &gt; Tabela de Financiamento &gt; Inclusão</h5> : (
                <h5>Parâmetros Cartão &gt; Tabela de Financiamento &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Código <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>Período de Vigência <span style={{ color: 'red' }}>*</span></label>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}><InputMask mask="99/99/9999" className="form-control" type="text" value={dtVigenciaInicio} onChange={(e) => setDtVigenciaInicio(e.target.value.replace(/_/g, ""))} /></th>
                  <th style={{ width: "15px" }}> a </th>
                  <th style={{ width: "150px" }}><InputMask mask="99/99/9999" className="form-control" type="text" value={dtVigenciaFim} onChange={(e) => setDtVigenciaFim(e.target.value.replace(/_/g, ""))} /></th>
                </tr>
              </thead>
            </div>
          </div>

          <h6 style={{ marginTop: '20px' }}>Prazos e Taxas</h6>
          <div className="row">
            <div className="col-md-1">
              <label>Prazo De: <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} required mask="999" type="text" className="form-control" value={prazoInicio} onChange={(e) => setPrazoInicio((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-1" style={{ marginLeft: '10px' }}>
              <label>Prazo Até: <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="999" type="text" className="form-control" value={prazoFim} onChange={(e) => setPrazoFim((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-2" style={{ marginLeft: '10px' }}>
              <label>Taxa <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" value={taxa} onChange={handleChangeTaxa} />
            </div>
            <div className="col-md-2" style={{ marginLeft: '10px' }}>
              <label>% Comissão</label>
              <input type="text" className="form-control" value={percentComissao} onChange={handleChangeComissao} />
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaTaxa()}>+
              </Button>
            </div>
            {tabelaTaxas.length > 0 ? (
              <div className="table-responsive" style={{ marginTop: '20px', width: '60%' }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col" id="App-header-table">Prazo De:</th>
                      <th scope="col" id="App-header-table">Prazo Até:</th>
                      <th scope="col" id="App-header-table">Taxa %</th>
                      <th scope="col" id="App-header-table">Comissão %</th>
                      <th scope="col" id="App-header-table">Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelaTaxas.map((item, index) => (
                      <tr>
                        <td>{item.prazoInicio}</td>
                        <td>{item.prazoFim}</td>
                        <td>{item.taxa != null ? item.taxa!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                        <td>{item.percentComissao != null ? item.percentComissao!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Button style={{ backgroundColor: '#dc3545', border: '1px solid #dc3545', color: 'white', cursor: 'pointer', textAlign: 'center' }} onClick={() => removerDadoTabelaTaxa(index)}>X</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : false}
          </div>

          <h6 style={{ marginTop: '20px' }}>Produtos</h6>
          <div className="row">
            <div className="col-md-2">
              <label>Tipo de Proposta <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={tpProposta} value={tpProposta} onChange={(e) => setTpProposta(e.target.value)}>
                {tipoProposta.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Produto <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={produto} value={produto} onChange={(e) => setProduto(e.target.value)}>
                {produtos.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label>Tipo de Formalização <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={formalizacao} value={formalizacao} onChange={(e) => setFormalizacao(e.target.value)}>
                {tipoFormalização.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaFinanciamentoProduto()}>+
              </Button>
            </div>
            {tabelaProdutosFinanciamento.length > 0 ? (
              <div className="table-responsive" style={{ marginTop: '20px', width: '60%' }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col" id="App-header-table">Tipo Proposta</th>
                      <th scope="col" id="App-header-table">Produto</th>
                      <th scope="col" id="App-header-table">Formalização</th>
                      <th scope="col" id="App-header-table">Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelaProdutosFinanciamento.map((item, index) => (
                      <tr>
                        <td>{getLabelTipoProp(item.tipoProposta)}</td>
                        <td>{item.produto.id} - {item.produto.nome}</td>
                        <td>{item.formalizacao}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Button style={{ backgroundColor: '#dc3545', border: '1px solid #dc3545', color: 'white', cursor: 'pointer', textAlign: 'center' }} onClick={() => removerDadoTabelaProduto(index)}>X</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : false}
          </div>
        </form>
      )}
      <div>
        <hr className="my-4" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
          <button className="w-40 btn btn-primary btn-lg btn btn-success" onClick={handleSubmit}>{tipo !== 'inclusao' ? 'Alterar Tabela' : 'Salvar Tabela'}</button>
        </div>
      </div>
    </div >
  );
}

export default FinanciamentoInclusaoAlteracao;