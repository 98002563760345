export class ResultadoSimulacaoPropostaDTO {
  public promotora!: string;
  public empregador!: string;
  public financiamento!: string;
  public produto!: string;
  public valorRMC!: number;
  public valorParcela!: number;
  public valorSolicitado!: number;
  public prazo!: number;

  public valorIof!: number;
  public valorPrincipal!: number;
  public valorRefin!: number;
  public valorCompra!: number;
  public valorLiquido!: number;
  public valorBruto!: number;
  public taxaClAM!: string;
  public taxaClAA!: string;
  public taxaCetAM!: string;
  public taxaCetAA!: string;
  public primeiroVencimento!: Date;
  public ultimoVencimento!: Date;
}