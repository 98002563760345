import { FinanciamentoTaxa } from "../model/financiamentoTaxa";

export class CalculoResultadoSimulacaoDTO {
  public promotora!: string;
  public empregador!: string;
  public financiamento!: string;
  public produto!: string;
  public valorRMC!: string;
  
  public valorLimite!: string;
  public limiteCompra!: string;
  public limiteSaque!: string;
  public tarifaEmissao!: string;
  public anuidade!: string;
  
  public dtNascimentoCliente!: Date;

  public prazos!: number[];
}