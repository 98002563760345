import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { Financiamento } from '../../../shared/model/financiamento';
import { format } from 'date-fns';
import { ButtonAlterar, ButtonAtivar, ButtonEmpregador, ButtonInativar, ButtonPromotora } from '../../../shared/buttons';
import FinanciamentoService from '../../../services/financiamentoService';
import ConfirmModal from '../../../shared/customAlertConfirm';

function FinanciamentoComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tabelaFinanciamento, setTabelaFinanciamento] = useState<Financiamento[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ action: string; id: any } | null>(null);


  const financiamentoService: FinanciamentoService = new FinanciamentoService();

  const listarTodosFinanciamentos = async () => {
    try {
      setLoading(true);
      const responseList = await financiamentoService.listarTodosFinanciamentos();
      setTabelaFinanciamento(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const excluirFinanciamento = (id: any) => {
    setCurrentAction({ action: 'excluir', id });
    setConfirmOpen(true);
  };

  const ativarInativarFinanciamento = (id: any, ativo: boolean, pendente: boolean) => {
    const action = (!ativo || pendente) ? 'ativar' : 'inativar';
    setCurrentAction({ action, id });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { action, id } = currentAction;

      try {
        if (action === 'excluir') {
          await financiamentoService.excluirFinanciamento(id);
        } else {
          await financiamentoService.ativarInativarFinanciamento(id);
        }
        listarTodosFinanciamentos();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listarTodosFinanciamentos();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Tabela de Financiamento</h5>
        <Button
          onClick={() => navigate('/parametros-financiamento/inclusao', { state: { id: null } })}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
        >
          + Nova Tabela
        </Button>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <div className="table-responsive">
          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">ID</th>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col" id="App-header-table">Tabela</th>
                  <th scope="col" id="App-header-table">Data Inicial</th>
                  <th scope="col" id="App-header-table">Data Final</th>
                  <th scope="col" id="App-header-table">Status</th>
                  <th scope="col" id="App-header-table">Ações</th>
                </tr>
              </thead>
              <tbody>
                {tabelaFinanciamento.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.codigo}</td>
                    <td>{item.nome}</td>
                    <td>{format(new Date(item.dtVigenciaInicio), 'dd/MM/yyyy')}</td>
                    <td>{format(new Date(item.dtVigenciaFim), 'dd/MM/yyyy')}</td>
                    <td>{item.pendente ? 'Pendente' : (item.ativo ? 'Ativo' : 'Inativo')}</td>
                    <td>
                      <a style={{ marginLeft: '10px' }}>
                        <Link to={`/parametros-financiamento/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </a>
                      <a style={{ marginLeft: '10px' }}>
                        <Link to={`/parametros-financiamento/promotora`} state={{ idFinanciamento: item.id, codigoFinanciamento: item.codigo, nomeFinanciamento: item.nome }}>
                          <ButtonPromotora />
                        </Link>
                      </a>
                      <a style={{ marginLeft: '10px' }}>
                        <Link to={`/parametros-financiamento/empregador`} state={{ idFinanciamento: item.id, codigoFinanciamento: item.codigo, nomeFinanciamento: item.nome }}>
                          <ButtonEmpregador />
                        </Link>
                      </a>
                      {item.pendente ? (
                        <a style={{ marginLeft: '10px' }}>
                          <ButtonInativar onClick={() => excluirFinanciamento(item.id)} />
                        </a>
                      ) : false}
                      <a style={{ marginLeft: '10px' }}>
                        {!item.ativo || item.pendente ?
                          <ButtonAtivar onClick={() => ativarInativarFinanciamento(item.id, item.ativo, item.pendente)} />
                          :
                          <ButtonInativar onClick={() => ativarInativarFinanciamento(item.id, item.ativo, item.pendente)} />
                        }
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {confirmOpen && (
              <ConfirmModal
                message={
                  currentAction?.action === 'excluir' ?
                    'Tem certeza que deseja excluir este item?' :
                    currentAction?.action === 'ativar' ?
                      'Tem certeza que deseja ativar este item?' :
                      'Tem certeza que deseja inativar este item?'
                }
                onConfirm={handleConfirm}
                onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FinanciamentoComponent;