import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { format } from 'date-fns';
import { PropostaObservacao } from '../../shared/model/propostaObservacao';
import { PropostaEsteira } from '../../shared/model/propostaEsteira';
import { ProcessoEsteiraDTO } from '../../shared/dto/processoEsteiraDTO';
import { PropostaDTO } from '../../shared/dto/propostaDTO';
import { AcaoPropostaEnum } from '../../shared/constantes/acaoPropostaEnum';
import { TipoDocumento } from '../../shared/constantes/tipoDocumento';
import { ArquivoDTO } from '../../shared/dto/arquivoDTO';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faUser, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getLabelSituacaoEsteiraEnum } from '../../shared/constantes/situacaoEsteiraEnum';
import ReprovarOuPendenciarPropostaModal from '../../shared/reprovarOuPendenciarPropostaModal';
import PropostaService from '../../services/propostaService';
import PropostaDocumentoService from '../../services/propostaDocumentoService';
import EsteiraService from '../../services/esteiraService';
import PropostaEsteiraService from '../../services/propostaEsteiraService';
import PropostaObservacaoService from '../../services/propostaObservacaoService';
import CustomAlert from '../../shared/customAlert';
import { getLabelTipoProp } from '../../shared/constantes/tiposProposta';

function PropostaEsteiraComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const navigate = useNavigate();
  const location = useLocation();
  const { idProposta, esteira } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [propostaObservacao, setPropostaObservacao] = useState<PropostaObservacao[]>([]);
  const [propostaEsteira, setPropostaEsteira] = useState<PropostaEsteira[]>([]);
  const [proposta, setProposta] = useState<PropostaDTO>();
  const [checkObsBanco, setCheckObsBanco] = useState(false);
  const [obs, setObs] = useState('');
  const [processoEsteiraDTO, setProcessoEsteiraDTO] = useState<ProcessoEsteiraDTO>();
  const tipoDocumento = TipoDocumento;
  const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState(0);
  const [arquivo, setArquivo] = useState<File | null>(null);
  const [arquivoBase64, setArquivoBase64] = useState<string>('');
  const [nomeArquivo, setNomeArquivo] = useState<string>('');
  const [arquivosDTO, setArquivosDTO] = useState<ArquivoDTO[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<AcaoPropostaEnum | null>(null);
  const [tipoPendenciaRecusa, setTipoPendenciaRecusa] = useState<string>('R');

  const propostaService: PropostaService = new PropostaService();
  const propostaDocumentoService: PropostaDocumentoService = new PropostaDocumentoService();
  const propostaEsteiraService: PropostaEsteiraService = new PropostaEsteiraService();
  const esteiraService: EsteiraService = new EsteiraService();
  const propostaObservacaoService: PropostaObservacaoService = new PropostaObservacaoService();
  
  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalConfirm = (motivoSelect: string) => {
    if (currentAction) {
      acaoProposta(currentAction, motivoSelect);
    }
    setShowModal(false);
  };


  const listarEsteiraProcesso = async () => {
    setLoading(true);
    try {
      const response = await esteiraService.listarEsteiraProcessoPorIdProposta(idProposta);
      const jsonResponse = response.data;
      
      setProcessoEsteiraDTO(jsonResponse);
      setProposta(jsonResponse.propostaDTO);
      setPropostaEsteira(jsonResponse.esteiraDTO.propostaEsteiraList);
      setPropostaObservacao(jsonResponse.esteiraDTO.propostaObservacaoList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleClickObs = async () => {
    setLoading(true);
    let propostaObs = new PropostaObservacao();
    propostaObs.obsBanco = checkObsBanco;
    propostaObs.observacao = obs;
    propostaObs.proposta = Number(idProposta);
    
    try {
      const response = await propostaObservacaoService.inclusaoPropostaObservacao(propostaObs);
      const jsonResponse = response.data;
      
      setPropostaObservacao([...propostaObservacao, jsonResponse]);
      setObs('');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const acaoProposta = async (acaoPropostaEnum: AcaoPropostaEnum, idMotivo: string | null) => {
    setLoading(true);
    try {
      const response = await propostaEsteiraService.incluirPropostaEsteira(idProposta, acaoPropostaEnum, idMotivo);
      const jsonResponse = response.data;
      
      setLoading(false);
      setAlert({ message: 'Ação ' + acaoPropostaEnum + ' realizada com sucesso!', type: 'success' });

      await sleep(2000);

      window.history.back()
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: 'Ação ' + acaoPropostaEnum + '! Erro ao realizar a ação.\n' + err.response.data, type: 'error' });
      console.error(err);
    }
  }

  const handleClickUpload = (event: any) => {
    setArquivo(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        setArquivoBase64(e.target.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    setNomeArquivo(event.target.files[0].name);
  }

  const uploadArquivo = async () => {
    if (tipoDocumentoSelecionado === 0) {
      setAlert({ message: 'Selecione o tipo de documento!', type: 'warning' });
    } else {
      if (arquivo) {
        setLoading(true);
        try {
          let arquivoDTO = new ArquivoDTO();
          arquivoDTO.arquivoBase64 = arquivoBase64.split(',')[1];
          arquivoDTO.idProposta = Number(idProposta);
          arquivoDTO.nomeArquivo = nomeArquivo;
          arquivoDTO.tipoDocumento = tipoDocumentoSelecionado;

          await propostaDocumentoService.inclusaoPropostaDocumento(arquivoDTO)
            .then((resp) => {
              setArquivosDTO([...arquivosDTO, resp.data]);
              setTipoDocumentoSelecionado(0);
              setNomeArquivo('')
              setLoading(false);
              setAlert({ message: 'Arquivo incluído com sucesso!', type: 'success' });
            });
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    }
  }

  const listarDocumentosProposta = async () => {
    setLoading(true);
    try {
      const response = await propostaDocumentoService.listarPropostaDocumentoPorIdProposta(idProposta);
      setArquivosDTO(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const downloadArquivo = async (caminhoArquivo: string, nomeArquivo: string) => {
    setLoading(true);
    try {
      await propostaDocumentoService.downloadPropostaDocumentoPorCaminhoArquivo(caminhoArquivo)
        .then((resp) => {
          const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
          const urlBlob = window.URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = urlBlob;
          linkDownload.download = nomeArquivo;
          document.body.appendChild(linkDownload);
          linkDownload.click();
          document.body.removeChild(linkDownload);
          window.URL.revokeObjectURL(urlBlob);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const downloadTodosArquivos = async () => {
    setLoading(true);
    try {
      await propostaDocumentoService.downloadLotePropostaDocumentoPorIdProposta(idProposta)
        .then((resp) => {
          const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
          const urlBlob = window.URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = urlBlob;
          linkDownload.download = "Proposta_" + idProposta + '.zip';
          document.body.appendChild(linkDownload);
          linkDownload.click();
          document.body.removeChild(linkDownload);
          window.URL.revokeObjectURL(urlBlob);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const excluirArquivo = async (caminhoArquivo: string, index: number) => {
    setLoading(true);
    try {
      await propostaDocumentoService.excluirPropostaDocumentoPorCaminhoArquivo(caminhoArquivo)
        .then(() => {
          const newTabelaIdade = [...arquivosDTO];
          newTabelaIdade.splice(index, 1);
          setArquivosDTO(newTabelaIdade);
        });
      setLoading(false);
      setAlert({ message: 'Arquivo excluído com sucesso!', type: 'success' });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const gerarRelatorioProposta = async (idProposta: number) => {
    setLoading(true);
    try {
      await propostaService.listarPropostaEspelhoJasper(idProposta)
      .then(resp => {
        const blob = new Blob([resp.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        
        setLoading(false);
      })

    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarEsteiraProcesso();
    listarDocumentosProposta();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        {esteira === 'S' ? <h5>Esteira &gt; Proposta &gt; Processos</h5> : <h5>Esteira &gt; Proposta &gt; Detalhes</h5>}
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}> Voltar</Button>
      </div>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : (
        <>
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Origem</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Promotora</span>
                    <input type="text" value={proposta?.promotora!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Supervisor</span>
                    <input type="text" value={proposta?.gerente!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Operador</span>
                    <input type="text" value={proposta?.operador!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Ponto de Venda</span>
                    <input type="text" value={proposta?.pontoVenda!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Empregador</span>
                    <input type="text" value={proposta?.empregador!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Secretaria/UPAG</span>
                    <input type="text" value={proposta?.orgao!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Cliente</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>CPF Cliente</span>
                    <input type="text" value={proposta?.cpfCliente!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }} />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Nome</span>
                    <input type="text" value={proposta?.nomeCliente!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Matricula</span>
                    <input type="text" value={proposta?.numeroBeneficiarioCliente!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Data de Nascimento</span>
                    <input type="text"
                      value={proposta?.dtNascimentoCliente ? format(new Date(proposta?.dtNascimentoCliente), 'dd/MM/yyyy') : ''}
                      className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>$ Proposta</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Tipo de Proposta</span>
                    <input type="text" value={getLabelTipoProp(proposta?.tipoProposta!)} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor RMC</span>
                    <input type="text" value={proposta?.valorRMC} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Limite Cartão</span>
                    <input type="text" value={proposta?.valorLimite} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Limite Compra</span>
                    <input type="text" value={proposta?.limiteCompra} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Limite Saque</span>
                    <input type="text" value={proposta?.limiteSaque} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Despesas</span>
                    <input type="text" value="0,00 - AAA" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor TC</span>

                    <input type="text" value="0,00 - AAA" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Anuidade</span>

                    <input type="text" value={proposta?.anuidade} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CL a.m.</span>

                    <input type="text" value={proposta?.taxaClAm} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CL a.a.</span>

                    <input type="text" value={proposta?.taxaClAa} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CET a.m.</span>
                    <input
                      type="text"
                      value={proposta?.taxaCetAm}
                      className="form-control form-control-sm brds"
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CET a.a.</span>
                    <input
                      type="text"
                      value={proposta?.taxaCetAa}
                      className="form-control form-control-sm brds"
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>$ Saque Parcelado</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Solicitado</span>
                    <input
                      type="text"
                      value={proposta?.valorSolicitado}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor IOF</span>
                    <input
                      type="text"
                      value={proposta?.valorIof}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Tarifa de Cadastro</span>
                    <input
                      type="text"
                      value="0,00 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Seguro</span>
                    <input
                      type="text"
                      value="0,00 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Financiado</span>
                    <input
                      type="text"
                      value={proposta?.valorPrincipal}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Líquido</span>
                    <input
                      type="text"
                      value={proposta?.valorLiquido}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Prazo</span>
                    <input
                      type="text"
                      value={proposta?.prazo}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Parcela</span>
                    <input
                      type="text"
                      value={proposta?.valorParcela}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Bruto</span>
                    <input
                      type="text"
                      value={proposta?.valorBruto}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Primeiro Vencimento</span>
                    <input
                      type="text"
                      value={proposta?.dtPrimeiroVencimento ? format(new Date(proposta?.dtPrimeiroVencimento), 'dd/MM/yyyy') : ''}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Último Vencimento</span>
                    <input
                      type="text"
                      value={proposta?.dtUltimoVencimento ? format(new Date(proposta?.dtUltimoVencimento), 'dd/MM/yyyy') : ''}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Tabela</span>
                    <input
                      type="text"
                      value={proposta?.codigoTabela}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Nome Tabela</span>
                    <input
                      type="text"
                      value={proposta?.tabela}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CL a.m.</span>
                    <input
                      type="text"
                      value={proposta?.taxaClAm}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CET a.m.</span>
                    <input
                      type="text"
                      value={proposta?.taxaCetAm}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa AP a.m.</span>
                    <input
                      type="text"
                      value="0,0 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa Nominal a.m.</span>
                    <input
                      type="text"
                      value="0,0 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CL a.a.</span>
                    <input
                      type="text"
                      value={proposta?.taxaClAa}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa CET a.a.</span>
                    <input
                      type="text"
                      value={proposta?.taxaCetAa}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa AP a.a.</span>
                    <input
                      type="text"
                      value="0,0 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Taxa Nominal a.a.</span>
                    <input
                      type="text"
                      value="0,0 - AAA"
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>$ Saque</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Valor Saque</span>
                    <input
                      type="text"
                      value={proposta?.limiteSaque}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Tipo de Conta</span>
                    <input
                      type="text"
                      value={proposta?.tipoConta}
                      readOnly
                      className="form-control form-control-sm brds"
                      style={{ color: 'Black', backgroundColor: 'LightGrey' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Banco</span>
                    <input
                      type="text"
                      value={proposta?.banco}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Agência</span>
                    <input
                      type="text"
                      value={proposta?.agencia}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>Conta</span>
                    <input
                      type="text"
                      value={proposta?.conta}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>DV</span>
                    <input
                      type="text"
                      value={proposta?.contaDv}
                      className="form-control form-control-sm brds"
                      readOnly
                      style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>$ Informações Adicionais</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="info">
                    <label>Nº do Contrato:</label>
                    <span> {proposta?.numeroContrato}</span>
                  </div>
                  <div className="info">
                    <label>Nº do Cartão:</label>
                    <span></span>
                  </div>
                  <div className="info">
                    <label>Nº Formalização:</label>
                    <span> {proposta?.idFormalizacao}</span>
                  </div>
                  <div className="info">
                    <label>ID Externo Proposta:</label>
                    <span></span>
                  </div>
                  <div className="info">
                    <label>Nº Contrato Consignado:</label>
                    <span> {proposta?.numeroContratoExterno}</span>
                  </div>
                  <div className="info">
                    <label>ADE:</label>
                    <span> {proposta?.adeAverbacao}</span>
                  </div>
                  <div className="info">
                    <label>URL CCB Não Assinada:</label>
                    <span></span>
                  </div>
                  <div className="info">
                    <label>URL CCB Assinada:</label>
                    <span></span>
                  </div>
                  <div className="info">
                    <label>ID Tabela:</label>
                    <span> {proposta?.codigoTabela}</span>
                  </div>
                  <div className="info">
                    <label>Código Esteira:</label>
                    <span> {proposta?.idEsteira}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Documentos</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <span>Tipo de Documento</span>
                    <span style={{ textAlign: 'left' }}></span>
                    <select
                      className="form-select"
                      onChange={(e) => setTipoDocumentoSelecionado(parseInt(e.target.value))}
                    >
                      <option value="0">Selecione uma opção</option>
                      {tipoDocumento.map((item) => (
                        <option key={item.label} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <span>Arquivo</span>
                    <br />
                    <input
                      style={{ backgroundColor: '#24393A', borderColor: '#24393A', color: 'White', width: '90%' }}
                      type="file" className="form-control-file btn btn-info"
                      onChange={(e) => handleClickUpload(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group" style={{ marginTop: '25px' }}>
                    <a className="btn btn-success">
                      <i className="fa fa-upload"
                        onClick={() => uploadArquivo()}> Importar Arquivo</i>
                    </a>
                  </div>
                </div>
                {arquivosDTO.length > 0 ? (
                  <div style={{ marginTop: '10px', visibility: 'visible', overflowY: 'auto', width: '100%', borderWidth: '1px' }}>
                    <div>
                      <table cellSpacing="2" cellPadding="4" rules="all" style={{ color: 'Black', backgroundColor: '#CCCCCC', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                        <tbody>
                          <tr className="GridHeaderStyle" style={{ backgroundColor: '#5a8e91', color: 'White' }}>
                            <th scope="col" id="App-header-table">Nome</th>
                            <th scope="col" id="App-header-table">Tipo</th>
                            <th scope="col" id="App-header-table">Data e Hora</th>
                            <th align="left" scope="col" id="App-header-table">
                              <a onClick={() => downloadTodosArquivos()}>
                                <Button style={{ background: 'black' }}>
                                  <FontAwesomeIcon icon={faDownload} />
                                </Button>
                              </a>
                            </th>
                            <th scope="col" id="App-header-table">&nbsp;</th></tr>
                          {arquivosDTO.map((item, index) => (
                            <tr className="GridRowStyle">
                              <td>{item.nomeArquivo}</td>
                              <td>{TipoDocumento.find(tipo => tipo.value === item.tipoDocumento.toString())?.label}</td>
                              <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</td>
                              <td align="left">
                                <a onClick={() => downloadArquivo(item.caminhoArquivo, item.nomeArquivo)}>
                                  <Button>
                                    <FontAwesomeIcon icon={faDownload} />
                                  </Button>
                                </a>
                              </td>
                              <td align="left">
                                <a onClick={() => excluirArquivo(item.caminhoArquivo, index)}>
                                  <Button style={{ background: 'red' }}>
                                    <FontAwesomeIcon icon={faClose} />
                                  </Button>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (false)}
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Observações</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>
                      Observação
                    </span>
                    <text display={'none'} className="form-control form-control-sm brds" style={{ color: 'Black', backgroundColor: 'LightGrey', height: '120px', textAlign: 'left' }}>
                      {propostaObservacao.map((item) => (
                        <>
                          <span>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</span>
                          <span> - {item.idUsuario} - </span>
                          <span> {item.observacao} </span>
                          <br />
                        </>
                      ))}
                    </text>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <span style={{ textAlign: 'left' }}>
                      Nova Observação
                    </span>
                  </div>
                  <div>
                    <textarea
                      style={{ height: '80px', textAlign: 'left', width: '100%' }}
                      value={obs}
                      onChange={(e) => setObs(e.target.value)}
                    />
                    <input
                      type="checkbox"
                      checked={checkObsBanco}
                      onChange={() => setCheckObsBanco(!checkObsBanco)}
                    />
                    <label>Observação interna do Banco</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <a className="btn btn-success" onClick={handleClickObs}>
                      <i className="fa fa-save"> Gravar Observação</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Status da Proposta</strong>
            </div>
            <div className="card-body">
              <div style={{ width: '100%', borderWidth: '1px' }}>
                <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                  <tbody>
                    <tr className="GridHeaderStyle" style={{ backgroundColor: '#5a8e91', color: 'White' }}>
                      <th scope="col" id="App-header-table">Status</th>
                      <th scope="col" id="App-header-table">Data</th>
                      <th scope="col" id="App-header-table">Usuário</th>
                      <th scope="col" id="App-header-table">Nr</th>
                      <th scope="col" id="App-header-table">Atividade</th>
                      <th scope="col" id="App-header-table">Ação</th>
                      <th scope="col" id="App-header-table">Motivo</th>
                    </tr>
                    {propostaEsteira.map((item) => (
                      <tr className="GridRowStyle">
                        <td>{getLabelSituacaoEsteiraEnum(item.situacaoProposta)}</td>
                        <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</td>
                        <td>Usuario</td>
                        <td>{item.numeroAtividade}</td>
                        <td>{item.atividade}</td>
                        <td>{item.acao ? item.acao : 'NaoDefinido'}</td>
                        <td>{item.motivoDescricao}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header ">
              <strong>Ações</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <a onClick={() => navigate('/esteira-cliente/alteracao', { state: { idCliente: proposta?.idCliente, edicao: 'edicao', idProposta: idProposta, atualizacaoCadastro: 'false' } })}
                      className="btn btn-secondary">
                      <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} />
                      Detalhes do Cliente
                    </a>
                    <a onClick={() => gerarRelatorioProposta(parseInt(idProposta!))}>
                      <Button style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Relatório'>
                        <FontAwesomeIcon icon={faPrint} style={{ marginRight: '10px' }} />
                        Proposta
                      </Button>
                    </a>
                    {esteira === 'S' && propostaEsteira[propostaEsteira.length - 1] !== undefined && propostaEsteira[propostaEsteira.length - 1].acao !== AcaoPropostaEnum.FINALIZADA ? (
                      <>
                        <a
                          className="btn btn-success">
                          <i className="fa fa-thumbs-up"
                            onClick={() => acaoProposta(AcaoPropostaEnum.APROVAR, null)}>
                            Aprovar
                          </i>
                        </a>
                        {propostaEsteira.length > 2 && propostaEsteira[propostaEsteira.length - 2].acao !== AcaoPropostaEnum.PENDENCIAR && (
                          <a
                            className="btn btn-secondary"
                            style={{ backgroundColor: 'YELLOW', color: 'BLACK' }}
                            onClick={() => {
                              setCurrentAction(AcaoPropostaEnum.PENDENCIAR);
                              setTipoPendenciaRecusa("P");
                              setShowModal(true);
                            }}
                          >
                            Pendenciar
                          </a>
                        )}
                        <a
                          className="btn btn-secondary"
                          style={{ backgroundColor: 'RED', color: 'White' }}
                          onClick={() => {
                            setCurrentAction(AcaoPropostaEnum.REPROVAR);
                            setTipoPendenciaRecusa("R");
                            setShowModal(true);
                          }}
                        >
                          Reprovar
                        </a>
                        <ReprovarOuPendenciarPropostaModal
                          showModal={showModal}
                          handleModalCancel={handleModalCancel}
                          handleModalConfirm={handleModalConfirm}
                          tipoPendenciaRecusa={tipoPendenciaRecusa}
                        />
                      </>
                    ) : false}
                    <a
                      style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}
                      className="btn btn-secondary"
                      onClick={() => window.history.back()}
                    >
                      <i className="fa fa-backward"> Voltar</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
}

export default PropostaEsteiraComponent;
